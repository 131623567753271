import { FunctionComponent, HTMLAttributes } from "react";
import styles from "./styles.module.scss";

export enum RoundedColorPreviewLabelPosition {
  Left = "left",
  Right = "right",
  Bottom = "bottom",
  Top = "top",
}

export type RoundedColorPreviewProps = Omit<HTMLAttributes<HTMLDivElement>, "children"> & {
  color?: string,
  label?: string | undefined,
  labelPosition?: RoundedColorPreviewLabelPosition,
};

const RoundedColorPreview: FunctionComponent<RoundedColorPreviewProps> = ({ className, color, label, labelPosition, ...props }: RoundedColorPreviewProps) => {
  return (
    <div className={`${styles.roundedColorPreview} ${styles[labelPosition ?? RoundedColorPreviewLabelPosition.Right]} ${className ?? ""}`} {...props}>
      <div style={{ backgroundColor: color }}></div>
      <span>{label}</span>
    </div>
  );
};

export default RoundedColorPreview;
