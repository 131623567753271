import { ChangeEventHandler, FunctionComponent, useEffect, useState } from "react";
import { format } from "../../utils/StringUtil";
import Textbox, { TextboxProps } from "../Textbox";

export enum FormattedTextboxTransform {
  Lowercase,
  Uppercase,
}

export type FormattedTextboxHTMLInputElement = HTMLInputElement & {
  formattedValue: string,
};

export type FormattedTextboxProps = Omit<TextboxProps, "onChange"> & {
  prefix?: string,
  blocks?: Array<number>,
  delimiters?: Array<string>,
  onChange?: ChangeEventHandler<FormattedTextboxHTMLInputElement>,
  transform?: FormattedTextboxTransform,
  allowedCharacters?: string,
  value?: string,
  defaultValue?: string,
};

const initialState = {
  formattedValue: "",
};

const FormattedTextbox: FunctionComponent<FormattedTextboxProps> = ({ prefix, blocks, delimiters, transform, allowedCharacters, value, defaultValue, onChange, onFocus, onBlur, ...props }: FormattedTextboxProps) => {
  const [formattedValue, setFormattedValue] = useState<string>(value ?? defaultValue ?? initialState.formattedValue);

  useEffect(() => {
    setFormattedValue(value !== undefined ? format(value, prefix, blocks, delimiters, allowedCharacters, transform).formattedValue : "");
  }, [value]);

  return (
    <Textbox
      value={formattedValue}
      onChange={(e) => {
        const formatted = format(e.target.value, prefix, blocks, delimiters, allowedCharacters, transform);

        if (value === undefined) {
          setFormattedValue(formatted.formattedValue);
        }

        if (onChange !== undefined) {
          onChange(Object.assign(e, {
            target: Object.assign(e.target, {
              value: formatted.value,
              formattedValue: formatted.formattedValue,
            }),
            currentTarget: Object.assign(e.target, {
              value: formatted.value,
              formattedValue: formatted.formattedValue,
            }),
          }));
        }
      }}
      onFocus={(e) => {
        if (prefix !== undefined && !formattedValue) {
          setFormattedValue(prefix);
        }
        if (onFocus !== undefined) {
          onFocus(e);
        }
      }}
      onBlur={(e) => {
        if (prefix !== undefined && formattedValue === prefix) {
          setFormattedValue(initialState.formattedValue);
        }
        if (onBlur !== undefined) {
          onBlur(e);
        }
      }}
      {...props}
    />
  );
};

export default FormattedTextbox;
