import { VoidFunctionComponent } from "react";
import Radio, { RadioProps } from "../Radio";
import radioButtonStyles from "../Radio/styles.module.scss";
import styles from "./styles.module.scss";

export type RadioButtonWithColorProps = RadioProps & {
  color: string,
  text: string
};

const RadioButtonWithColor: VoidFunctionComponent<RadioButtonWithColorProps> = ({ className, color, text, ...props }: RadioButtonWithColorProps) => {
  return (
    <label className={`${radioButtonStyles.radioButton} ${styles.radioButtonWithColor} ${className ?? ""} `}>
      <Radio {...props} />
      <div className={`${styles.wrapper}`}>
        <div className={`${styles.text}`}>
          {text}
        </div>

        <div className={`${styles.color}`} style={{
          backgroundColor: `${color}`
        }}>
          {text} selection
        </div>
      </div>
    </label>
  );
};

export default RadioButtonWithColor;