import { forwardRef, PropsWithChildren } from "react";
import Checkbox, { CheckboxProps, CheckboxRef } from "../Checkbox";
import styles from "./styles.module.scss";

export enum CheckboxButtonHeight {
  High = "high",
  Low = "low",
}

export type CheckboxButtonProps = PropsWithChildren<CheckboxProps> & {
  height?: CheckboxButtonHeight,
};

export type CheckboxButtonRef = CheckboxRef;

const CheckboxButton = ({ className, children, disabled, height, ...props }: CheckboxButtonProps, ref: CheckboxButtonRef) => {
  return (
    <label className={`${styles.checkboxButton} ${styles[height ?? CheckboxButtonHeight.High]} ${disabled ? styles.disabled : ""} ${className ?? ""}`}>
      <Checkbox ref={ref} disabled={disabled} {...props} />
      <div className={styles.content}>{children}</div>
    </label>
  );
};

export default forwardRef(CheckboxButton);
