import { ButtonHTMLAttributes, FunctionComponent } from "react";
import styles from "./styles.module.scss";

/**
 * @todo Move to main file.
 */
export enum Theme {
  Light = "light",
  Dark = "dark",
}

export enum BorderedButtonShape {
  Rectangle = "rectangle",
  Ellipse = "ellipse",
}

export enum BorderedButtonPosition {
  Left = "left",
  Right = "right",
  TextLeft = "textLeft",
  TextRight = "textRight",
}

export type BorderedButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  icon?: JSX.Element | undefined,
  iconPosition?: BorderedButtonPosition,
  theme?: Theme,
  shape?: BorderedButtonShape,
};

/**
 * @deprecated Will be removed in future versions. Use {@link ServiceButton} instead.
 */
const BorderedButton: FunctionComponent<BorderedButtonProps> = ({ children, className, icon, iconPosition, shape, theme, ...props }: BorderedButtonProps) => {
  return (
    <button className={`${styles.borderedButton} ${styles[iconPosition ?? BorderedButtonPosition.TextRight]} ${styles[shape ?? BorderedButtonShape.Rectangle]} ${styles[theme ?? Theme.Light]} ${className ?? ""}`} {...props}>
      {icon !== undefined && (
        <span className={`${styles.icon}`}>{icon}</span>
      )}
      {children}
    </button>
  );
};

export default BorderedButton;
