// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_left__WmzKR{text-align:left}.styles_center__NXExc{text-align:center}.styles_right__uIwsM{text-align:right}", "",{"version":3,"sources":["webpack://./src/components/TextAlignWrapper/styles.module.scss"],"names":[],"mappings":"AAKE,oBACE,eAAA,CADF,sBACE,iBAAA,CADF,qBACE,gBAAA","sourcesContent":["@use \"sass:string\";\n\n$text-aligns: \"left\", \"center\", \"right\";\n\n@each $textAlign in $text-aligns {\n  .#{$textAlign} {\n    text-align: string.unquote($textAlign);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"left": "styles_left__WmzKR",
	"center": "styles_center__NXExc",
	"right": "styles_right__uIwsM"
};
export default ___CSS_LOADER_EXPORT___;
