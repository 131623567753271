// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_heading2__3GzDJ{font-family:var(--font-main);font-weight:700;font-size:24px;line-height:36px;color:var(--heading-2-color-default)}.styles_heading2__3GzDJ.styles_light__Sa3Fa{--heading-2-color-default: var(--color-mine-shaft)}.styles_heading2__3GzDJ.styles_dark__mgcOV{--heading-2-color-default: var(--color-soft-white)}.styles_heading2__3GzDJ.styles_regular__btZ42{font-weight:400}", "",{"version":3,"sources":["webpack://./src/components/Heading2/styles.module.scss"],"names":[],"mappings":"AAAA,wBACE,4BAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,oCAAA,CAEA,4CACE,kDAAA,CAGF,2CACE,kDAAA,CAGF,8CACE,eAAA","sourcesContent":[".heading2 {\n  font-family: var(--font-main);\n  font-weight: 700;\n  font-size: 24px;\n  line-height: 36px;\n  color: var(--heading-2-color-default);\n\n  &.light {\n    --heading-2-color-default: var(--color-mine-shaft);\n  }\n\n  &.dark {\n    --heading-2-color-default: var(--color-soft-white);\n  }\n\n  &.regular {\n    font-weight: 400;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading2": "styles_heading2__3GzDJ",
	"light": "styles_light__Sa3Fa",
	"dark": "styles_dark__mgcOV",
	"regular": "styles_regular__btZ42"
};
export default ___CSS_LOADER_EXPORT___;
