import { FunctionComponent } from "react";
import FormattedTextbox, { FormattedTextboxProps } from "../FormattedTextbox";
import InputDescription, { DescriptionStyle } from "../InputDescription";
import InputError, { ErrorStyle } from "../InputError";
import InputLabel from "../InputLabel";
import { TextAlign } from "../TextAlignWrapper";
import styles from "./styles.module.scss";

export type FormattedInputProps = FormattedTextboxProps & {
  label?: string,
  error?: string,
  errorStyle?: ErrorStyle,
  textAlign?: TextAlign,
  description?: string,
  descriptionStyle?: DescriptionStyle,
};

const FormattedInput: FunctionComponent<FormattedInputProps> = ({ className, label, error, errorStyle, disabled, description, descriptionStyle, ...props }: FormattedInputProps) => {
  return (
    <label className={`${styles.formattedInput} ${className ?? ""}`}>
      {label && <InputLabel disabled={disabled}>{label}</InputLabel>}
      <FormattedTextbox disabled={disabled} className={error !== undefined ? styles.error : ""} {...props} />
      <div className={styles.infoContainer}>
        <InputDescription descriptionStyle={descriptionStyle}>{!error ? description : undefined}</InputDescription>
        <InputError errorStyle={errorStyle}>{error}</InputError>
      </div>
    </label>
  );
};

export default FormattedInput;
