import { ButtonHTMLAttributes, DetailedHTMLProps, FunctionComponent } from "react";
import styles from "./styles.module.scss";

export type BorderedFlatButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

/**
 * @deprecated Will be removed in future versions. Use {@link ServiceButton} instead.
 */
const BorderedFlatButton: FunctionComponent<BorderedFlatButtonProps> = ({ className, children, ...props }: BorderedFlatButtonProps) => {
  return (
    <button className={`${styles.borderedFlatButton} ${className ?? ""}`} {...props}>
      {children}
    </button>
  );
};

export default BorderedFlatButton;