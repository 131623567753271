// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_password__40-ta .styles_inputWrapper__C3X0-{position:relative;width:100%}.styles_password__40-ta .styles_inputWrapper__C3X0- .styles_icon__-8Tr3{position:absolute;right:0;width:24px;height:24px;cursor:pointer}.styles_password__40-ta .styles_inputWrapper__C3X0- .styles_textbox__AyYBl{box-sizing:border-box;padding-right:24px}", "",{"version":3,"sources":["webpack://./src/components/Password/styles.module.scss"],"names":[],"mappings":"AAIE,oDACE,iBAAA,CACA,UAAA,CAEA,wEACE,iBAAA,CACA,OAAA,CACA,UAVS,CAWT,WAXS,CAYT,cAAA,CAGF,2EACE,qBAAA,CACA,kBAjBS","sourcesContent":[".password {\n  $icon-width: 24px;\n  $icon-height: $icon-width;\n\n  .inputWrapper {\n    position: relative;\n    width: 100%;\n\n    .icon {\n      position: absolute;\n      right: 0;\n      width: $icon-width;\n      height: $icon-height;\n      cursor: pointer;\n    }\n\n    .textbox {\n      box-sizing: border-box;\n      padding-right: $icon-width;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"password": "styles_password__40-ta",
	"inputWrapper": "styles_inputWrapper__C3X0-",
	"icon": "styles_icon__-8Tr3",
	"textbox": "styles_textbox__AyYBl"
};
export default ___CSS_LOADER_EXPORT___;
