// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_page__XKrI3{width:100%;min-height:100vh}", "",{"version":3,"sources":["webpack://./src/components/Page/styles.module.scss"],"names":[],"mappings":"AAEA,oBACE,UAAA,CACA,gBAAA","sourcesContent":["@import \"../../styles/mixins/flex.scss\";\n\n.page {\n  width: 100%;\n  min-height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "styles_page__XKrI3"
};
export default ___CSS_LOADER_EXPORT___;
