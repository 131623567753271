import { FunctionComponent, RefAttributes } from "react";
import { Link, LinkProps } from "react-router-dom";
import styles from "./styles.module.scss";

export enum LinkWithIconPosition {
  TextLeft = "textLeft",
  TextRight = "textRight",
}

export type LinkWithIconProps = LinkProps & RefAttributes<HTMLAnchorElement> & {
  icon?: JSX.Element,
  iconPosition?: LinkWithIconPosition,
  disabled?: boolean,
};

const LinkWithIcon: FunctionComponent<LinkWithIconProps> = ({ className, disabled, icon, iconPosition, children, onClick, ...props }: LinkWithIconProps) => {
  return (
    <Link
      className={`${styles.linkWithIcon} ${styles[iconPosition ?? LinkWithIconPosition.TextLeft]} ${disabled ? styles.disabled : ""} ${className ?? ""}`}
      onClick={(e) => {
        if (disabled) {
          e.preventDefault();
        }
        else if (onClick !== undefined) {
          onClick(e);
        }
      }}
      {...props}
    >
      <span className={styles.icon}>
        {icon}
      </span>
      {children && (
        <span>
          {children}
        </span>
      )}
    </Link>
  );
};

export default LinkWithIcon;