import { FunctionComponent, InputHTMLAttributes } from "react";
import Checkbox from "../Checkbox";
import styles from "./styles.module.scss";

export type LabeledCheckboxProps = InputHTMLAttributes<HTMLInputElement>

const LabeledCheckbox: FunctionComponent<LabeledCheckboxProps> = ({ className, children, ...props }: LabeledCheckboxProps) => {
  return (
    <label className={`${styles.labelCheckbox} ${className ?? ""}`}>
      <Checkbox {...props} />
      <div>
        {children}
      </div>
    </label>
  );
};

export default LabeledCheckbox;
