// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_radios__ZTtlE{display:flex;flex-direction:column;justify-content:center;align-items:center;width:100%}@supports(display: flex) and (gap: 24px){.styles_radios__ZTtlE{gap:24px}}@supports(display: flex) and (not (gap: 24px)){.styles_radios__ZTtlE *+*{margin-top:24px}}.styles_radios__ZTtlE>*{width:100%}", "",{"version":3,"sources":["webpack://./src/components/Radios/styles.module.scss","webpack://./src/styles/mixins/flex.scss"],"names":[],"mappings":"AAEA,sBCDE,YAAA,CACA,qBDCc,CCEZ,sBDFoB,CCMpB,kBDN4B,CAG9B,UAAA,CCwBA,yCD5BF,sBC6BI,QD3BuB,CAAA,CC8BzB,+CACE,0BAII,eDnCmB,CAAA,CAIzB,wBACE,UAAA","sourcesContent":["@import \"../../styles/mixins/flex.scss\";\n\n.radios {\n  @include flex(column, center, center);\n  @include flex-column-gap(24px, \"*\");\n\n  width: 100%;\n\n  & > * {\n    width: 100%;\n  }\n}\n","@mixin flex($flexDirection, $justifyContent: null, $alignItems: null) {\n  display: flex;\n  flex-direction: $flexDirection;\n\n  @if $justifyContent {\n    justify-content: $justifyContent;\n  }\n\n  @if $alignItems {\n    align-items: $alignItems;\n  }\n}\n\n@mixin flex-row-gap($gap, $fallbackSelector, $fallbackGap: null) {\n  @supports (display: flex) and (gap: $gap) {\n    gap: $gap;\n  }\n\n  @supports (display: flex) and (not (gap: $gap)) {\n    #{$fallbackSelector} + #{$fallbackSelector} {\n      @if $fallbackGap {\n        margin-left: $fallbackGap;\n      } @else {\n        margin-left: $gap;\n      }\n    }\n  }\n}\n\n@mixin flex-column-gap($gap, $fallbackSelector, $fallbackGap: null) {\n  @supports (display: flex) and (gap: $gap) {\n    gap: $gap;\n  }\n\n  @supports (display: flex) and (not (gap: $gap)) {\n    #{$fallbackSelector} + #{$fallbackSelector} {\n      @if $fallbackGap {\n        margin-top: $fallbackGap;\n      } @else {\n        margin-top: $gap;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radios": "styles_radios__ZTtlE"
};
export default ___CSS_LOADER_EXPORT___;
