import { FunctionComponent, RefAttributes } from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import styles from "./styles.module.scss";

export type LinkProps = RouterLinkProps & RefAttributes<HTMLAnchorElement> & {
  disabled?: boolean,
};

const Link: FunctionComponent<LinkProps> = ({ children, className, disabled, onClick, ...props }: LinkProps) => {
  return (
    <RouterLink
      className={`${styles.link} ${disabled ? styles.disabled : ""} ${className ?? ""}`}
      onClick={(e) => {
        if (disabled) {
          e.preventDefault();
        }
        else if (onClick !== undefined) {
          onClick(e);
        }
      }}
      {...props}
    >
      {children}
    </RouterLink>
  );
};

export default Link;
