import { FunctionComponent, InputHTMLAttributes } from "react";
import ColorPreview from "../ColorPreview";
import Radio from "../Radio/index";
import styles from "./styles.module.scss";

export type ColorRadioProps = InputHTMLAttributes<HTMLInputElement> & {
  color?: string,
};

const ColorRadio: FunctionComponent<ColorRadioProps> = ({ children, className, color, disabled, style, ...props }: ColorRadioProps) => {
  return (
    <label className={`${className ?? ""} ${styles.colorRadio} ${disabled ? styles.disabled : ""}`} style={style}>
      <ColorPreview
        className={styles.colorPreview}
        style={Object.assign({}, { backgroundColor: `${disabled ? "var(--color-iq-silver)" : color}` }, style)}
      />
      <div style={{ color: `${disabled ? "var(--color-iq-silver)" : ""}` }} className={`${styles.text}`}>
        {children}
      </div>
      <Radio disabled={disabled} {...props} />
    </label>
  );
};

export default ColorRadio;
