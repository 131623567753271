import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { areSame } from "../utils";
import { InitialStateFunction } from "./useStateWithReset";

type UseStateWithDefaultArrayResponse<S1, S2> = [S1, S2, Dispatch<SetStateAction<S1>>, Dispatch<SetStateAction<S2>>, VoidFunction, VoidFunction, boolean, boolean];

type UseStateWithDefaultObjectResponse<S1, S2> = {
  value: S1,
  error: S2,
  setValue: Dispatch<SetStateAction<S1>>,
  setError: Dispatch<SetStateAction<S2>>,
  clearValue: VoidFunction,
  clearError: VoidFunction,
  isDefaultValue: boolean,
  isDefaultError: boolean,
};

type UseStateWithDefaultResponse<S1, S2> = UseStateWithDefaultArrayResponse<S1, S2> & UseStateWithDefaultObjectResponse<S1, S2>;

export default function useStateWithError<S1, S2>(initialState: S1 | InitialStateFunction<S1>, initialErrorState: S2 | InitialStateFunction<S2>): UseStateWithDefaultResponse<S1, S2> {
  const [value, setValue] = useState<S1>(initialState);
  const clearValue = useCallback(() => {
    setValue(typeof initialState === "function" ? (initialState as InitialStateFunction<S1>)() : initialState);
  }, [initialState, setValue]);
  const isDefaultValue = useMemo(() => {
    return areSame(value, (typeof initialState === "function" ? (initialState as InitialStateFunction<S1>)() : initialState));
  }, [initialState, value]);

  const [error, setError] = useState<S2>(initialErrorState);
  const clearError = useCallback(() => {
    setError(typeof initialErrorState === "function" ? (initialErrorState as InitialStateFunction<S2>)() : initialErrorState);
  }, [initialErrorState, setError]);
  const isDefaultError = useMemo(() => {
    return areSame(error, (typeof initialErrorState === "function" ? (initialErrorState as InitialStateFunction<S2>)() : initialErrorState));
  }, [initialErrorState, error]);

  const arrayResponse: UseStateWithDefaultArrayResponse<S1, S2> = [value, error, setValue, setError, clearValue, clearError, isDefaultValue, isDefaultError];
  const objectResponse: UseStateWithDefaultObjectResponse<S1, S2> = {
    value: value,
    error: error,
    setValue: setValue,
    setError: setError,
    clearValue: clearValue,
    clearError: clearError,
    isDefaultValue: isDefaultValue,
    isDefaultError: isDefaultError,
  };

  return Object.assign(arrayResponse, objectResponse);
}
