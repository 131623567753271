// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_labelCheckbox__SWvyH{display:flex;vertical-align:middle;font-family:var(--font-main);font-weight:400;font-size:14px;line-height:24px;color:var(--color-iq-tuna);gap:12px;cursor:pointer}", "",{"version":3,"sources":["webpack://./src/components/LabeledCheckbox/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,YAAA,CACA,qBAAA,CACA,4BAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,0BAAA,CACA,QAAA,CACA,cAAA","sourcesContent":[".labelCheckbox {\n  display: flex;\n  vertical-align: middle;\n  font-family: var(--font-main);\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 24px;\n  color: var(--color-iq-tuna);\n  gap: 12px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labelCheckbox": "styles_labelCheckbox__SWvyH"
};
export default ___CSS_LOADER_EXPORT___;
