import { ButtonHTMLAttributes, ForwardedRef, forwardRef, memo } from "react";
import styles from "./styles.module.scss";

export enum LargeButtonIconPosition {
  LeftCenter = "leftCenter",
  RightCenter = "rightCenter",
}

export type LargeButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  icon?: JSX.Element,
  iconPosition?: LargeButtonIconPosition,
};

export type LargeButtonRef = ForwardedRef<HTMLButtonElement>;

const LargeButton = ({ className, icon, iconPosition, children, ...props }: LargeButtonProps, ref: LargeButtonRef) => {
  return (
    <button className={`${styles.largeButton} ${styles[iconPosition ?? LargeButtonIconPosition.LeftCenter]} ${className ?? ""}`} {...props} ref={ref}>
      {icon !== undefined && (
        <span className={`${styles.icon}`}>{icon}</span>
      )}
      {children}
    </button>
  );
};

export default memo(forwardRef(LargeButton));