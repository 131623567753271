// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../img/search.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_search__PREtV::after{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat left;color:rgba(0,0,0,0);margin-top:-24px;content:\".\"}.styles_search__PREtV *{transition:border-color .2s,fill .2s,stroke .2s}.styles_search__PREtV input{padding-left:28px;box-sizing:border-box}", "",{"version":3,"sources":["webpack://./src/components/Search/styles.module.scss"],"names":[],"mappings":"AAEE,6BACE,iEAAA,CACA,mBAAA,CACA,gBAAA,CACA,WAAA,CAGF,wBACE,+CAAA,CAGF,4BACE,iBAAA,CACA,qBAAA","sourcesContent":[".search {\n\n  &::after {\n    background: url(\"../../img/search.svg\") no-repeat left;\n    color: transparent;\n    margin-top: -24px;\n    content: \".\";\n  }\n\n  * {\n    transition: border-color 0.2s, fill 0.2s, stroke 0.2s;\n  }\n\n  input {\n    padding-left: 28px;\n    box-sizing: border-box;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search": "styles_search__PREtV"
};
export default ___CSS_LOADER_EXPORT___;
