import { ForwardedRef, forwardRef, InputHTMLAttributes } from "react";
import styles from "./styles.module.scss";

export type RadioProps = Omit<InputHTMLAttributes<HTMLInputElement>, "type" | "children">;

export type RadioRef = ForwardedRef<HTMLInputElement>;

const Radio = ({ className, ...props }: RadioProps, ref: RadioRef) => {
  return (
    <input
      ref={ref}
      type="radio"
      className={`${styles.radio} ${className ?? ""}`}
      {...props}
    />
  );
};

export default forwardRef(Radio);
