import { ButtonHTMLAttributes, FunctionComponent } from "react";
import { Theme } from "..";
import styles from "./styles.module.scss";

export enum SimpleButtonShape {
  Rounded = "rounded",
  Rectangular = "rectangular",
}

export type SimpleButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  shape?: SimpleButtonShape,
  theme?: Theme,
};

const SimpleButton: FunctionComponent<SimpleButtonProps> = ({ children, className, shape, theme, ...props }: SimpleButtonProps) => {
  return (
    <button className={`${styles.simpleButton} ${styles[shape ?? SimpleButtonShape.Rounded]} ${styles[theme ?? Theme.Light]} ${className ?? ""}`} {...props}>
      {children}
    </button>
  );
};

export default SimpleButton;
