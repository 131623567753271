import { DetailedHTMLProps, FunctionComponent, OptionHTMLAttributes, SelectHTMLAttributes } from "react";
import InputError, { ErrorStyle } from "../InputError";
import InputLabel from "../InputLabel";
import TextAlignWrapper, { TextAlign } from "../TextAlignWrapper";
import styles from "./styles.module.scss";

export type DropdownDefaultOptionProps = Omit<DetailedHTMLProps<OptionHTMLAttributes<HTMLOptionElement>, HTMLOptionElement>, "value"> & {
  value: string | number,
};

const DropdownDefaultOption: FunctionComponent<DropdownDefaultOptionProps> = ({ className, children, ...props }: DropdownDefaultOptionProps) => {
  return (
    <option className={`${styles.dropdownDefaultOption} ${className ?? ""}`} {...props}>{children}</option>
  );
};

export type DropdownProps = SelectHTMLAttributes<HTMLSelectElement> & {
  label?: string | undefined,
  error?: string | undefined,
  errorStyle?: ErrorStyle,
  textAlign?: TextAlign,
};

const Dropdown: FunctionComponent<DropdownProps> = ({ className, disabled, label, children, error, errorStyle, textAlign, ...props }: DropdownProps) => {
  return (
    <label className={`${styles.dropdown} ${className ?? ""}`}>
      {label && <InputLabel disabled={disabled}>{label}</InputLabel>}
      <TextAlignWrapper textAlign={textAlign ?? TextAlign.Left}>
        <select
          className={`${styles.select} ${error !== undefined ? styles.error : ""}`}
          disabled={disabled}
          {...props}
        >
          {children}
        </select>
      </TextAlignWrapper>
      <div className={styles.errorContainer}>
        <InputError errorStyle={errorStyle}>{error}</InputError>
      </div>
    </label>
  );
};

export { DropdownDefaultOption };
export default Dropdown;
