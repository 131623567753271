import { DetailedHTMLProps, FunctionComponent, HTMLAttributes, TableHTMLAttributes, TdHTMLAttributes, ThHTMLAttributes } from "react";
import styles from "./styles.module.scss";

export type TableProps = DetailedHTMLProps<TableHTMLAttributes<HTMLTableElement>, HTMLTableElement>;

export type TableRowProps = DetailedHTMLProps<HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>;

export type TableCellProps = DetailedHTMLProps<TdHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement>;

export type TableHeaderCellProps = DetailedHTMLProps<ThHTMLAttributes<HTMLTableCellElement>, HTMLTableCellElement>;

const TableRow: FunctionComponent<TableRowProps> = ({ className, children, ...props }: TableRowProps) => {
  return (
    <tr className={`${styles.tableRow} ${className ?? ""}`} {...props}>
      {children}
    </tr>
  );
};

const TableCell: FunctionComponent<TableCellProps> = ({ className, children, ...props }: TableCellProps) => {
  return (
    <td className={`${styles.tableCell} ${className ?? ""}`} {...props}>
      <div className={styles.content}>
        {children}
      </div>
    </td>
  );
};

const TableHeaderCell: FunctionComponent<TableHeaderCellProps> = ({ className, children, ...props }: TableHeaderCellProps) => {
  return (
    <th className={`${styles.tableHeaderCell} ${className ?? ""}`} {...props}>
      <div className={styles.content}>
        {children}
      </div>
    </th>
  );
};

const Table: FunctionComponent<TableProps> = ({ className, children, ...props }: TableProps) => {
  return (
    <table className={`${styles.table} ${className ?? ""}`} {...props}>
      {children}
    </table>
  );
};

export { TableRow, TableCell, TableHeaderCell };
export default Table;
