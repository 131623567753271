import { ImgHTMLAttributes, VoidFunctionComponent } from "react";
import { HorizontalAlign, VerticalAlign } from "..";
import FlexContainer from "../FlexContainer/index";
import RoundedColorPreview from "../RoundedColorPreview/index";
import styles from "./styles.module.scss";

export type DevicePreviewProps = ImgHTMLAttributes<HTMLImageElement> & {
  modelText?: string,
  colorText?: string,
  color?: string,
};

const DevicePreview: VoidFunctionComponent<DevicePreviewProps> = ({ src, className, modelText, alt, colorText, color, ...props }: DevicePreviewProps) => {
  return (
    <FlexContainer verticalAlign={VerticalAlign.Middle} horizontalAlign={HorizontalAlign.Center} className={`${styles.devicePreview} ${className ?? ""}`}>
      <FlexContainer width={352} height={385} className={`${styles.cardContainer} ${src === undefined ? styles.noDeviceImage : ""}`}>
        {src !== undefined && (
          <>
            <img className={styles.image} src={src} alt={alt} {...props} />
            <span className={`${styles.roundedColorWrapper}`}>
              <p className={`${styles.text}`}>{modelText}</p>
              <RoundedColorPreview color={color} label={colorText} />
            </span>
          </>
        )}
      </FlexContainer>
    </FlexContainer>
  );
};

export default DevicePreview;
