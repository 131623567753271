import { HTMLAttributes, VoidFunctionComponent } from "react";
import styles from "./styles.module.scss";

export type ColorPreviewProps = Omit<HTMLAttributes<HTMLDivElement>, "children"> & {
  color?: string,
};

const ColorPreview: VoidFunctionComponent<ColorPreviewProps> = ({ className, color, style, ...props }: ColorPreviewProps) => {
  return (
    <div
      className={`${styles.colorPreview} ${className ?? ""}`}
      style={Object.assign({}, { backgroundColor: `${color}` }, style)}
      {...props}
    />
  );
};

export default ColorPreview;
