import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { areSame } from "../utils";

export type InitialStateFunction<S> = () => S;

type UseStateWithResetArrayResponse<S> = [S, Dispatch<SetStateAction<S>>, VoidFunction, boolean];

type UseStateWithResetObjectResponse<S> = {
  value: S,
  setValue: Dispatch<SetStateAction<S>>,
  clearValue: VoidFunction,
  isDefault: boolean,
};

type UseStateWithResetResponse<S> = UseStateWithResetArrayResponse<S> & UseStateWithResetObjectResponse<S>;

export default function useStateWithReset<S>(initialState: S | InitialStateFunction<S>): UseStateWithResetResponse<S> {
  const [value, setValue] = useState<S>(initialState);
  const clearValue = useCallback(() => {
    setValue(typeof initialState === "function" ? (initialState as InitialStateFunction<S>)() : initialState);
  }, [initialState, setValue]);
  const isDefault = useMemo(() => {
    return areSame(value, (typeof initialState === "function" ? (initialState as InitialStateFunction<S>)() : initialState));
  }, [initialState, value]);

  const arrayResponse: UseStateWithResetArrayResponse<S> = [value, setValue, clearValue, isDefault];
  const objectResponse: UseStateWithResetObjectResponse<S> = {
    value: value,
    setValue: setValue,
    clearValue: clearValue,
    isDefault: isDefault,
  };

  return Object.assign(arrayResponse, objectResponse);
}