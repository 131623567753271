// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_serviceText__EQOoO{font-family:var(--font-main);font-style:normal;font-weight:400;font-size:14px;line-height:24px;color:var(--service-text-color-default)}.styles_serviceText__EQOoO.styles_light__s1cAY{--service-text-color-default: var(--color-mine-shaft)}.styles_serviceText__EQOoO.styles_dark__omRT9{--service-text-color-default: var(--color-soft-white)}", "",{"version":3,"sources":["webpack://./src/components/ServiceText/styles.module.scss"],"names":[],"mappings":"AAAA,2BACE,4BAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,uCAAA,CAEA,+CACE,qDAAA,CAGF,8CACE,qDAAA","sourcesContent":[".serviceText {\n  font-family: var(--font-main);\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 24px;\n  color: var(--service-text-color-default);\n\n  &.light {\n    --service-text-color-default: var(--color-mine-shaft);\n  }\n\n  &.dark {\n    --service-text-color-default: var(--color-soft-white);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"serviceText": "styles_serviceText__EQOoO",
	"light": "styles_light__s1cAY",
	"dark": "styles_dark__omRT9"
};
export default ___CSS_LOADER_EXPORT___;
