import { ChangeEvent, ForwardedRef, forwardRef, useImperativeHandle, useRef } from "react";
import CheckboxButton, { CheckboxButtonProps } from "../CheckboxButton";
import Textbox from "../Textbox";
import styles from "./styles.module.scss";

export type CheckboxButtonWithTextboxChangeEvent = ChangeEvent<HTMLInputElement> & {
  target: EventTarget & HTMLInputElement & {
    checked: boolean;
    value: string | number | undefined;
    text: string;
  };
}

export type CheckboxButtonWithTextboxProps = Omit<CheckboxButtonProps, "onChange"> & {
  onChange?: (event: CheckboxButtonWithTextboxChangeEvent) => void,
  text?: string,
};

export type CheckboxButtonWithTextboxRef = ForwardedRef<{
  checkbox: HTMLInputElement,
  textbox: HTMLInputElement,
}>;

const CheckboxButtonWithTextbox = ({ className, checked, children, disabled, placeholder, onChange, text, ...props }: CheckboxButtonWithTextboxProps, ref: CheckboxButtonWithTextboxRef) => {
  const checkbox = useRef<HTMLInputElement>(null);
  const textbox = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => ({
    checkbox: checkbox.current as HTMLInputElement,
    textbox: textbox.current as HTMLInputElement,
  }), []);

  return (
    <CheckboxButton
      className={`${styles.checkboxButtonWithTextbox} ${className ?? ""}`}
      ref={checkbox}
      checked={checked}
      disabled={disabled}
      onChange={(e) => {
        if (textbox.current !== null) {
          if (e.target.checked) {
            textbox.current.focus();
          }
          if (onChange !== undefined) {
            const newTarget = Object.assign(e.target, {
              text: textbox.current.value,
            });
            const newEvent = Object.assign({}, e, {
              target: newTarget,
            });
            onChange(newEvent);
          }
        }
      }}
      {...props}
    >
      <div
        className={styles.content}
      >
        {children}
        <div className={styles.inputWrapper}>
          <Textbox
            ref={textbox}
            className={`${styles.input} ${disabled ? styles.disabled : undefined}`}
            type="text"
            placeholder={placeholder}
            disabled={disabled}
            onChange={(e) => {
              if (checkbox.current !== null && onChange !== undefined) {
                const newTarget = Object.assign(checkbox.current, {
                  checked: true,
                  value: props.value,
                  text: e.target.value,
                });
                const newEvent = Object.assign({}, e, {
                  target: newTarget,
                });
                onChange(newEvent);
              }
            }}
            value={text}
          />
        </div>
      </div>
    </CheckboxButton>
  );
};

export default forwardRef(CheckboxButtonWithTextbox);