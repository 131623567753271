import { DetailedHTMLProps, FunctionComponent, HTMLAttributes } from "react";
import styles from "./styles.module.scss";

export type InputLabelProps = DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> & {
  disabled?: boolean,
};

const InputLabel: FunctionComponent<InputLabelProps> = ({ className, children, disabled, ...props }: InputLabelProps) => {
  return (
    <span className={`${styles.inputLabel} ${disabled ? styles.disabled : ""} ${className ?? ""}`} {...props}>
      {children}
    </span>
  );
};

export default InputLabel;