import React, { ChangeEvent, FormEvent, FunctionComponent, HTMLAttributes, useRef, useState } from "react";
import ColorRadio from "../ColorRadio";
import LabeledRadio from "../LabeledRadio";
import Radio from "../Radio";
import RadioButton, { RadioButtonProps } from "../RadioButton";
import RadioButtonWithColor from "../RadioButtonWithColor";
import RadioButtonWithTextbox, { RadioButtonWithTextboxChangeEvent } from "../RadioButtonWithTextbox";
import styles from "./styles.module.scss";

export type RadiosWithTextboxChangeEvent = FormEvent<HTMLDivElement> & {
  target: EventTarget & HTMLInputElement & {
    value: string | number | undefined,
    text: string | undefined,
  },
};

export type RadiosProps = Omit<HTMLAttributes<HTMLDivElement>, "children" | "onChange"> & {
  children: Array<JSX.Element>,
  onChange?: (event: RadiosWithTextboxChangeEvent) => void,
  name?: string,
  value?: string | number,
  text?: string,
};

const Radios: FunctionComponent<RadiosProps> = ({ className, name, children, onChange, value, text, ...props }: RadiosProps) => {
  const div = useRef<HTMLDivElement>(null);
  const [selected, setSelected] = useState<string | number | undefined>(value);

  return (
    <div
      ref={div}
      className={`${className ?? ""} ${styles.radios}`}
      {...props}
    >
      {React.Children.map(children, function (oldChild, index) {
        if (oldChild.type === Radio || oldChild.type === LabeledRadio || oldChild.type === RadioButton || oldChild.type === RadioButtonWithColor || oldChild.type === RadioButtonWithTextbox || oldChild.type === ColorRadio) {
          const oldProps = oldChild.props as RadioButtonProps;
          if (process.env.NODE_ENV === "development") {
            if (oldProps.value === undefined) {
              console.warn(`The "value" property of "Radio" element is not set. The default one "${index}" is given.`);
            }
          }
          // Does not work with "===".
          // eslint-disable-next-line eqeqeq
          const checked = selected == oldProps.value;
          const newProps = Object.assign({}, oldProps, {
            name: name ?? oldProps.name,
            checked: checked,
            value: oldProps.value ?? index,
            text: (oldChild.type === RadioButtonWithTextbox && checked) ? text : oldChild.props.text,
            onChange: (e: ChangeEvent<HTMLInputElement>) => {
              if (oldProps.onChange !== undefined) {
                oldProps.onChange(e);
              }
              if (div.current !== null) {
                const newTarget = Object.assign(div.current, {
                  value: e.target.value,
                  text: (e as RadioButtonWithTextboxChangeEvent).target.text ?? e.target.value,
                });
                const newEvent = Object.assign({}, e, {
                  target: newTarget,
                  currentTarget: newTarget,
                });
                setSelected(e.target.value);
                if (onChange !== undefined) {
                  onChange(newEvent);
                }
              }
            }
          });
          if (process.env.NODE_ENV === "development") {
            if (name !== undefined && oldProps.name !== undefined && name !== oldProps.name) {
              console.warn(`The name "${oldProps.name}" of "Radio" inside the "Radios" component is overriden by "${name}" one.`);
            }
          }
          const newChild = Object.assign({}, oldChild, { props: newProps });
          return newChild;
        }
        if (process.env.NODE_ENV === "development") {
          console.warn(`The children of "Radios" component should be "Radio" or "RadioWithTextbox", "${oldChild.type}" found.`);
        }
        return undefined;
      })}
    </div>
  );
};

export default Radios;