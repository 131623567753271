import { ButtonHTMLAttributes, FunctionComponent } from "react";
import styles from "./styles.module.scss";

export type SmallBorderedButtonProps = ButtonHTMLAttributes<HTMLButtonElement>

const SmallBorderedButton: FunctionComponent<SmallBorderedButtonProps> = ({ children, className, ...props }: SmallBorderedButtonProps) => {
  return (
    <button className={`${styles.smallBorderedButton} ${className ?? ""}`} {...props}>
      {children}
    </button>
  );
};

export default SmallBorderedButton;
