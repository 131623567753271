import { forwardRef } from "react";
import { Direction } from "..";
import FlexContainer, { FlexContainerProps, FlexContainerRef } from "../FlexContainer";
import styles from "./styles.module.scss";

export type PageProps = Omit<FlexContainerProps, "direction"> & {
  header?: JSX.Element,
  footer?: JSX.Element,
};

export type PageRef = FlexContainerRef;

const Page = ({ className, children, header, footer, ...props }: PageProps, ref: PageRef) => {
  return (
    <FlexContainer
      ref={ref}
      className={`${styles.page} ${className ?? ""}`}
      direction={Direction.Column} {...props}
    >
      {header}
      {children}
      {footer}
    </FlexContainer>
  );
};

export default forwardRef(Page);