import { ForwardedRef, forwardRef, InputHTMLAttributes, memo } from "react";
import { ErrorAnimation, ErrorStyle, InputError, InputLabel, InputWrapper, Textbox, Theme } from "..";
import styles from "./styles.module.scss";

export type SimpleInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, "type"> & {
  label?: string,
  theme?: Theme,
  error?: string,
  disabled?: boolean,
  type?: "text" | "tel" | "email" | "number" | "password",
};

export type SimpleInputRef = ForwardedRef<HTMLInputElement>;

const SimpleInput = ({ className, label, disabled, error, theme, ...props }: SimpleInputProps, ref: SimpleInputRef) => {
  return (
    <InputWrapper className={`${styles.simpleInput} ${className ?? ""} ${disabled ? styles.disabled : ""} ${error ? styles.error : ""} ${styles[theme ?? Theme.Light]}`} error={error} disabled={disabled} theme={theme}>
      {label ? <InputLabel className={styles.label} disabled={disabled}>{label}</InputLabel> : ""}
      <Textbox className={styles.textbox} disabled={disabled} theme={theme} ref={ref} {...props}/>
      <InputError className={styles.error} errorStyle={ErrorStyle.Fixed} animation={ErrorAnimation.FadeIn}>{error}</InputError>
    </InputWrapper>
  );
};

export default memo(forwardRef(SimpleInput));
