import { ForwardedRef, forwardRef, memo } from "react";
import { Textbox, TextboxProps } from "../index";
import InputDescription, { DescriptionStyle } from "../InputDescription";
import InputError, { ErrorStyle } from "../InputError";
import InputLabel from "../InputLabel";
import { TextAlign } from "../TextAlignWrapper";
import styles from "./styles.module.scss";

export type InputProps = TextboxProps & {
  label?: string,
  error?: string,
  type?: "text" | "tel" | "email" | "number" | "password",
  errorStyle?: ErrorStyle,
  textAlign?: TextAlign,
  description?: string,
  descriptionStyle?: DescriptionStyle,
};

export type InputRef = ForwardedRef<HTMLInputElement>;

const Input = ({ className, label, error, errorStyle, disabled, description, descriptionStyle, ...props }: InputProps, ref: InputRef) => {
  return (
    <label className={`${styles.input} ${className ?? ""}`}>
      {label && <InputLabel disabled={disabled}>{label}</InputLabel>}
      <Textbox ref={ref} disabled={disabled} className={error !== undefined ? styles.error : ""} {...props} />
      <div className={styles.infoContainer}>
        <InputDescription descriptionStyle={descriptionStyle}>{!error ? description : undefined}</InputDescription>
        <InputError errorStyle={errorStyle}>{error}</InputError>
      </div>
    </label>
  );
};

export default memo(forwardRef(Input));
