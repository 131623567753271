import { DetailedHTMLProps, FunctionComponent, HTMLAttributes } from "react";
import { Theme } from "..";
import TextAlignWrapper, { TextAlign } from "../TextAlignWrapper";
import styles from "./styles.module.scss";

export type Heading1Props = DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> & {
  textAlign?: TextAlign,
  theme?: Theme,
};

const Heading1: FunctionComponent<Heading1Props> = ({ className, children, textAlign, theme, ...props }: Heading1Props) => {
  return (
    <TextAlignWrapper textAlign={textAlign ?? TextAlign.Left}>
      <h1 className={`${styles.heading1} ${styles[theme ?? Theme.Light]} ${className ?? ""}`} {...props}>
        {children}
      </h1>
    </TextAlignWrapper>
  );
};

export default Heading1;
