// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_colorPreview__cz7Qn{width:80px;height:36px;border-radius:8px}", "",{"version":3,"sources":["webpack://./src/components/ColorPreview/styles.module.scss"],"names":[],"mappings":"AAAA,4BACE,UAAA,CACA,WAAA,CACA,iBAAA","sourcesContent":[".colorPreview {\n  width: 80px;\n  height: 36px;\n  border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colorPreview": "styles_colorPreview__cz7Qn"
};
export default ___CSS_LOADER_EXPORT___;
