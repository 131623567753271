import { forwardRef } from "react";
import { VerticalAlign } from "..";
import FlexContainer, { FlexContainerProps, FlexContainerRef } from "../FlexContainer";
import styles from "./styles.module.scss";

export type ContentProps = FlexContainerProps & {
  padding?: number | string,
};

export type ContentRef = FlexContainerRef;

const Content = ({ className, verticalAlign, padding, style, ...props }: ContentProps, ref: ContentRef) => {
  return (
    <FlexContainer
      ref={ref}
      className={`${styles.content} ${className ?? ""}`}
      style={(padding) ?
        Object.assign({
          padding: typeof padding === "number" ? `${padding}px` : padding,
        }, style) :
        style
      }
      verticalAlign={verticalAlign ?? VerticalAlign.Top}
      {...props}
    />
  );
};

export default forwardRef(Content);