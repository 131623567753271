// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_inputWrapper__dHh5r{--input-wrapper-outline-color-default: transparent;--input-wrapper-outline-color-hover: var(--color-turquoise);--input-wrapper-background-color-active: var(--color-wild-sand);outline:none;padding:8px 12px;display:block;border-radius:8px;transition:background-color 200ms,outline 200ms,padding 200ms,box-shadow 200ms;cursor:pointer;box-sizing:border-box;box-shadow:0 0 0 1px var(--input-wrapper-outline-color-default)}.styles_inputWrapper__dHh5r[focus-within]{background:var(--input-wrapper-background-color-active)}.styles_inputWrapper__dHh5r:focus-within{background:var(--input-wrapper-background-color-active)}.styles_inputWrapper__dHh5r:hover{box-shadow:0 0 0 1px var(--input-wrapper-outline-color-hover)}.styles_inputWrapper__dHh5r.styles_error__Zxvzh{padding:8px 12px}", "",{"version":3,"sources":["webpack://./src/components/InputWrapper/styles.module.scss"],"names":[],"mappings":"AAAA,4BACE,kDAAA,CACA,2DAAA,CACA,+DAAA,CAEA,YAAA,CACA,gBAAA,CACA,aAAA,CACA,iBAAA,CACA,8EAAA,CACA,cAAA,CACA,qBAAA,CACA,+DAAA,CAEA,0CACE,uDAAA,CAGF,yCACE,uDAAA,CAGF,kCACE,6DAAA,CAGF,gDACE,gBAAA","sourcesContent":[".inputWrapper {\n  --input-wrapper-outline-color-default: transparent;\n  --input-wrapper-outline-color-hover: var(--color-turquoise);\n  --input-wrapper-background-color-active: var(--color-wild-sand);\n\n  outline: none;\n  padding: 8px 12px;\n  display: block;\n  border-radius: 8px;\n  transition: background-color 200ms, outline 200ms, padding 200ms, box-shadow 200ms;\n  cursor: pointer;\n  box-sizing: border-box;\n  box-shadow: 0 0 0 1px var(--input-wrapper-outline-color-default);\n\n  &[focus-within] {\n    background: var(--input-wrapper-background-color-active);\n  }\n\n  &:focus-within {\n    background: var(--input-wrapper-background-color-active);\n  }\n\n  &:hover {\n    box-shadow: 0 0 0 1px var(--input-wrapper-outline-color-hover);\n  }\n\n  &.error {\n    padding: 8px 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputWrapper": "styles_inputWrapper__dHh5r",
	"error": "styles_error__Zxvzh"
};
export default ___CSS_LOADER_EXPORT___;
