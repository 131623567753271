// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_inputDescription__UsOz5{color:var(--color-dove-gray);font-size:12px;line-height:12px}.styles_inputDescription__UsOz5.styles_floating__XGQKa{position:absolute}", "",{"version":3,"sources":["webpack://./src/components/InputDescription/styles.module.scss"],"names":[],"mappings":"AAAA,gCACE,4BAAA,CACA,cAAA,CACA,gBAAA,CAEA,uDACE,iBAAA","sourcesContent":[".inputDescription {\n  color: var(--color-dove-gray);\n  font-size: 12px;\n  line-height: 12px;\n\n  &.floating {\n    position: absolute;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputDescription": "styles_inputDescription__UsOz5",
	"floating": "styles_floating__XGQKa"
};
export default ___CSS_LOADER_EXPORT___;
