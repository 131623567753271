import { ForwardedRef, forwardRef, InputHTMLAttributes } from "react";
import { Theme } from "../index";
import TextAlignWrapper, { TextAlign } from "../TextAlignWrapper";
import styles from "./styles.module.scss";

export type TextboxProps = Omit<InputHTMLAttributes<HTMLInputElement>, "type"> & {
  textAlign?: TextAlign,
  theme?: Theme,
  type?: "text" | "tel" | "email" | "number" | "password",
};

export type InputRef = ForwardedRef<HTMLInputElement>;

const Textbox = ({ className, textAlign, theme, type, ...props }: TextboxProps, ref: InputRef) => {
  return (
    <TextAlignWrapper textAlign={textAlign ?? TextAlign.Left}>
      <input ref={ref} type={type} className={`${styles.textbox} ${styles[theme ?? Theme.Light]} ${className ?? ""}`} {...props} />
    </TextAlignWrapper>
  );
};

export default forwardRef(Textbox);
