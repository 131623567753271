import { VoidFunctionComponent } from "react";
import type { TextboxProps } from "../Textbox/index";
import Textbox from "../Textbox/index";
import styles from "./styles.module.scss";

export type SearchProps = TextboxProps;

const Search: VoidFunctionComponent<SearchProps> = ({ className, disabled, ...props }: SearchProps) => {
  return (
    <Textbox className={`${styles.search} ${disabled ? styles.disabled : ""} ${className ?? ""}`} disabled={disabled} {...props} />
  );
};

export default Search;
