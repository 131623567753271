import { DetailedHTMLProps, FunctionComponent, HTMLAttributes } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./animation.scss";
import styles from "./styles.module.scss";

export enum DescriptionStyle {
  Fixed,
  Floating,
}

export type InputDescriptionProps = DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> & {
  descriptionStyle?: DescriptionStyle,
};

const InputDescription: FunctionComponent<InputDescriptionProps> = ({ className, children, descriptionStyle, ...props }: InputDescriptionProps) => {
  return (
    <TransitionGroup>
      <CSSTransition
        key={children ? children.toString() : ""}
        timeout={400}
        classNames="description-fade"
      >
        <span className={`${styles.inputDescription} ${descriptionStyle === DescriptionStyle.Floating ? styles.floating : ""} ${className ?? ""}`} {...props}>
          {children}
        </span>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default InputDescription;