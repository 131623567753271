import { useEffect, useState } from "react";

type UsePageExitMessageArrayResponse = [VoidFunction, VoidFunction, boolean];

type UsePageExitMessageObjectResponse = {
  enablePageExitMessage: VoidFunction,
  disablePageExitMessage: VoidFunction,
  isEnabledPageExitMessage: boolean,
}

export default function usePageExitMessage() {
  const [isEnabledPageExitMessage, setIsEnabledPageExitMessage] = useState(false);
  const enablePageExitMessage = () => {
    setIsEnabledPageExitMessage(true);
  };
  const disablePageExitMessage = () => {
    setIsEnabledPageExitMessage(false);
  };

  useEffect(() => {
    window.onbeforeunload = isEnabledPageExitMessage ? () => "" : null;
  }, [isEnabledPageExitMessage]);

  const arrayResponse: UsePageExitMessageArrayResponse = [enablePageExitMessage, disablePageExitMessage, isEnabledPageExitMessage];
  const objectResponse: UsePageExitMessageObjectResponse = {
    enablePageExitMessage: enablePageExitMessage,
    disablePageExitMessage: disablePageExitMessage,
    isEnabledPageExitMessage: isEnabledPageExitMessage,
  };
  return Object.assign(arrayResponse, objectResponse);
}