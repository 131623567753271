import { DetailedHTMLProps, FunctionComponent, HTMLAttributes } from "react";
import { CSSTransition, SwitchTransition, TransitionGroup } from "react-transition-group";
import "./animation.scss";
import styles from "./styles.module.scss";

export enum ErrorStyle {
  Fixed = "fixed",
  Floating = "floating",
}

export enum ErrorAnimation {
  FlyIn = "error-fly-in",
  FadeIn = "error-fade-in",
}

export type InputErrorProps = DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> & {
  errorStyle?: ErrorStyle,
  animation?: ErrorAnimation,
};

const InputError: FunctionComponent<InputErrorProps> = ({ className, children, errorStyle, animation, ...props }: InputErrorProps) => {
  return (
    <SwitchTransition>
      <CSSTransition
        key={children ? children.toString() : ""}
        timeout={200}
        classNames={animation ?? ErrorAnimation.FlyIn}
      >
        <span className={`${styles.inputError} ${styles[errorStyle ?? ErrorStyle.Fixed] ?? ""} ${className ?? ""}`} {...props}>
          {children}
        </span>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default InputError;