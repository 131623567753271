// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_inputError__vx\\+xJ{color:var(--color-pomegranate);font-size:12px;line-height:12px;display:block;font-style:italic}.styles_inputError__vx\\+xJ.styles_floating__eUC35{position:absolute}", "",{"version":3,"sources":["webpack://./src/components/InputError/styles.module.scss"],"names":[],"mappings":"AAAA,2BACE,8BAAA,CACA,cAAA,CACA,gBAAA,CACA,aAAA,CACA,iBAAA,CAEA,kDACE,iBAAA","sourcesContent":[".inputError {\n  color: var(--color-pomegranate);\n  font-size: 12px;\n  line-height: 12px;\n  display: block;\n  font-style: italic;\n\n  &.floating {\n    position: absolute;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputError": "styles_inputError__vx+xJ",
	"floating": "styles_floating__eUC35"
};
export default ___CSS_LOADER_EXPORT___;
