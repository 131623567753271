import { AssertionError, deepStrictEqual } from "assert";

export function areSame<T>(first: T, second: T, ...args: Array<T>): boolean {
  try {
    deepStrictEqual(first, second);
    for (let i = 0; i < args.length; i++) {
      deepStrictEqual(first, args[i]);
    }
    return true;
  }
  catch (error) {
    if (error instanceof AssertionError) {
      return false;
    }
    throw error;
  }
}
