import { DetailedHTMLProps, FunctionComponent, HTMLAttributes } from "react";
import { Theme } from "..";
import TextAlignWrapper, { TextAlign } from "../TextAlignWrapper";
import styles from "./styles.module.scss";

export type ServiceTextProps = DetailedHTMLProps<HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement> & {
  textAlign?: TextAlign,
  theme?: Theme
};

const ServiceText: FunctionComponent<ServiceTextProps> = ({ className, children, theme, textAlign, ...props }: ServiceTextProps) => {
  return (
    <TextAlignWrapper textAlign={textAlign ?? TextAlign.Left}>
      <p className={`${styles.serviceText} ${styles[theme ?? Theme.Light]} ${className ?? ""}`} {...props}>
        {children}
      </p>
    </TextAlignWrapper>
  );
};

export default ServiceText;