import { ChangeEventHandler, ForwardedRef, forwardRef, memo, useEffect, useState } from "react";
import { format } from "../../utils/StringUtil";
import { ErrorAnimation, ErrorStyle, InputError, InputLabel, Theme } from "../index";
import styles from "../SimpleInput/styles.module.scss";
import Textbox, { TextboxProps } from "../Textbox";
import InputWrapper from "../InputWrapper";

export enum FormattedTextboxTransform {
  Lowercase,
  Uppercase,
}

export type FormattedSimpleInputHTMLInputElement = HTMLInputElement & {
  formattedValue: string,
};

export type FormattedSimpleInputProps = Omit<TextboxProps, "onChange"> & {
  prefix?: string,
  blocks?: Array<number>,
  delimiters?: Array<string>,
  onChange?: ChangeEventHandler<FormattedSimpleInputHTMLInputElement>,
  transform?: FormattedTextboxTransform,
  allowedCharacters?: string,
  value?: string,
  defaultValue?: string,
  label?: string,
  theme?: Theme,
  error?: string,
  disabled?: boolean,
};

const initialState = {
  formattedValue: "",
};

export type FormattedSimpleInputRef = ForwardedRef<HTMLInputElement>;

const FormattedSimpleInput = ({ className, disabled, theme, error, label, prefix, blocks, delimiters, transform, allowedCharacters, value, defaultValue, onChange, onFocus, onBlur, ...props }: FormattedSimpleInputProps, ref: FormattedSimpleInputRef) => {
  const [formattedValue, setFormattedValue] = useState<string>(value ?? defaultValue ?? initialState.formattedValue);

  useEffect(() => {
    setFormattedValue(value !== undefined ? format(value, prefix, blocks, delimiters, allowedCharacters, transform).formattedValue : "");
  }, [value]);

  return (
    <InputWrapper className={`${styles.simpleInput} ${className ?? ""} ${disabled ? styles.disabled : ""} ${error ? styles.error : ""} ${styles[theme ?? Theme.Light]}`} error={error} disabled={disabled} theme={theme}>
      {label ? <InputLabel className={styles.label} disabled={disabled}>{label}</InputLabel> : ""}
      <Textbox
        ref={ref}
        value={formattedValue}
        className={styles.textbox}
        disabled={disabled}
        theme={theme}
        onChange={(e) => {
          const formatted = format(e.target.value, prefix, blocks, delimiters, allowedCharacters, transform);

          if (value === undefined) {
            setFormattedValue(formatted.formattedValue);
          }

          if (onChange !== undefined) {
            onChange(Object.assign(e, {
              target: Object.assign(e.target, {
                value: formatted.value,
                formattedValue: formatted.formattedValue,
              }),
              currentTarget: Object.assign(e.target, {
                value: formatted.value,
                formattedValue: formatted.formattedValue,
              }),
            }));
          }
        }}
        onFocus={(e) => {
          if (prefix !== undefined && !formattedValue) {
            setFormattedValue(prefix);
          }
          if (onFocus !== undefined) {
            onFocus(e);
          }
        }}
        onBlur={(e) => {
          if (prefix !== undefined && formattedValue === prefix) {
            setFormattedValue(initialState.formattedValue);
          }
          if (onBlur !== undefined) {
            onBlur(e);
          }
        }}
        {...props}
      />
      <InputError className={styles.error} errorStyle={ErrorStyle.Fixed} animation={ErrorAnimation.FadeIn}>{error}</InputError>
    </InputWrapper>
  );
};

export default memo(forwardRef(FormattedSimpleInput));
