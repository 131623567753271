// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_labelRadio__b3-bf{display:flex;vertical-align:middle;font-family:\"Noto Sans\",sans-serif;font-weight:400;font-size:14px;line-height:24px;color:var(--color-iq-tuna);gap:12px}", "",{"version":3,"sources":["webpack://./src/components/LabeledRadio/styles.module.scss"],"names":[],"mappings":"AAAA,0BACE,YAAA,CACA,qBAAA,CACA,kCAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,0BAAA,CACA,QAAA","sourcesContent":[".labelRadio {\n  display: flex;\n  vertical-align: middle;\n  font-family: \"Noto Sans\", sans-serif;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 24px;\n  color: var(--color-iq-tuna);\n  gap: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labelRadio": "styles_labelRadio__b3-bf"
};
export default ___CSS_LOADER_EXPORT___;
