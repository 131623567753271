import { ButtonHTMLAttributes, DetailedHTMLProps, FunctionComponent } from "react";
import styles from "./styles.module.scss";

export type FlatButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

/**
 * @deprecated Will be removed in future versions. Use {@link ServiceButton} instead.
 */
const FlatButton: FunctionComponent<FlatButtonProps> = ({ className, children, ...props }: FlatButtonProps) => {
  return (
    <button className={`${styles.flatButton} ${className ?? ""}`} {...props}>
      {children}
    </button>

  );
};

export default FlatButton;