import { FunctionComponent, InputHTMLAttributes } from "react";
import Radio from "../Radio";
import styles from "./styles.module.scss";

export type LabeledRadioProps = InputHTMLAttributes<HTMLInputElement>

const LabeledRadio: FunctionComponent<LabeledRadioProps> = ({ className, children, ...props }: LabeledRadioProps) => {
  return (
    <label className={`${styles.labelRadio} ${className ?? ""}`}>
      <Radio {...props} />
      {children}
    </label>
  );
};

export default LabeledRadio;
