// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_inputLabel__I58Vv{--input-label-color-default: var(--color-iq-silver);--input-label-color-disabled: var(--color-iq-silver);color:var(--input-label-color-default);font-weight:500;font-size:12px;line-height:12px;cursor:pointer;font-family:var(--font-main)}.styles_inputLabel__I58Vv.styles_disabled__Ppyba{cursor:not-allowed;color:var(--input-label-color-disabled)}", "",{"version":3,"sources":["webpack://./src/components/InputLabel/styles.module.scss"],"names":[],"mappings":"AAAA,0BACE,mDAAA,CACA,oDAAA,CAEA,sCAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,cAAA,CACA,4BAAA,CAEA,iDACE,kBAAA,CACA,uCAAA","sourcesContent":[".inputLabel {\n  --input-label-color-default: var(--color-iq-silver);\n  --input-label-color-disabled: var(--color-iq-silver);\n\n  color: var(--input-label-color-default);\n  font-weight: 500;\n  font-size: 12px;\n  line-height: 12px;\n  cursor: pointer;\n  font-family: var(--font-main);\n\n  &.disabled {\n    cursor: not-allowed;\n    color: var(--input-label-color-disabled);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputLabel": "styles_inputLabel__I58Vv",
	"disabled": "styles_disabled__Ppyba"
};
export default ___CSS_LOADER_EXPORT___;
