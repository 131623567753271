// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_radioButtonWithTextbox__EH3mj{--radio-button-with-textbox-border-color-default: var(--color-iq-tuna);--radio-button-with-textbox-border-color-active: var(--color-iq-tuna);--radio-button-with-textbox-color-default: var(--color-dove-gray);--radio-button-with-textbox-color-active: var(--color-iq-tuna)}.styles_radioButtonWithTextbox__EH3mj .styles_content__KYHF6{display:flex;gap:12px;flex-flow:row wrap}.styles_radioButtonWithTextbox__EH3mj .styles_content__KYHF6 .styles_inputWrapper__Xgos0{flex-grow:1}.styles_radioButtonWithTextbox__EH3mj .styles_content__KYHF6 .styles_textbox__BPnA3{min-width:80px}.styles_radioButtonWithTextbox__EH3mj .styles_content__KYHF6 .styles_textbox__BPnA3:not(.styles_disabled__Dtt73) *{cursor:text !important}", "",{"version":3,"sources":["webpack://./src/components/RadioButtonWithTextbox/styles.module.scss"],"names":[],"mappings":"AAEA,sCACE,sEAAA,CACA,qEAAA,CACA,iEAAA,CACA,8DAAA,CAEA,6DACE,YAAA,CACA,QAAA,CACA,kBAAA,CAEA,yFACE,WAAA,CAGF,oFACE,cAAA,CAIE,mHACE,sBAAA","sourcesContent":["@import \"../../styles/mixins/flex.scss\";\n\n.radioButtonWithTextbox {\n  --radio-button-with-textbox-border-color-default: var(--color-iq-tuna);\n  --radio-button-with-textbox-border-color-active: var(--color-iq-tuna);\n  --radio-button-with-textbox-color-default: var(--color-dove-gray);\n  --radio-button-with-textbox-color-active: var(--color-iq-tuna);\n\n  .content {\n    display: flex;\n    gap: 12px;\n    flex-flow: row wrap;\n\n    .inputWrapper {\n      flex-grow: 1;\n    }\n\n    .textbox {\n      min-width: 80px;\n\n      &:not(.disabled) {\n\n        * {\n          cursor: text !important;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radioButtonWithTextbox": "styles_radioButtonWithTextbox__EH3mj",
	"content": "styles_content__KYHF6",
	"inputWrapper": "styles_inputWrapper__Xgos0",
	"textbox": "styles_textbox__BPnA3",
	"disabled": "styles_disabled__Dtt73"
};
export default ___CSS_LOADER_EXPORT___;
