// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_checkboxButtonWithTextbox__\\+oZzu{--checkbox-button-with-textbox-border-color-default: var(--color-iq-tuna);--checkbox-button-with-textbox-border-color-active: var(--color-iq-tuna);--checkbox-button-with-textbox-color-default: var(--color-dove-gray);--checkbox-button-with-textbox-color-active: var(--color-iq-tuna)}.styles_checkboxButtonWithTextbox__\\+oZzu .styles_content__DNcFh{display:flex;gap:12px;flex-flow:row wrap}.styles_checkboxButtonWithTextbox__\\+oZzu .styles_content__DNcFh .styles_inputWrapper__G5ASE{flex-grow:1}.styles_checkboxButtonWithTextbox__\\+oZzu .styles_content__DNcFh .styles_textbox__KtMV5{min-width:80px}.styles_checkboxButtonWithTextbox__\\+oZzu .styles_content__DNcFh .styles_textbox__KtMV5:not(.styles_disabled__NiKJ3) *{cursor:text !important}", "",{"version":3,"sources":["webpack://./src/components/CheckboxButtonWithTextbox/styles.module.scss"],"names":[],"mappings":"AAEA,0CACE,yEAAA,CACA,wEAAA,CACA,oEAAA,CACA,iEAAA,CAEA,iEACE,YAAA,CACA,QAAA,CACA,kBAAA,CAEA,6FACE,WAAA,CAGF,wFACE,cAAA,CAIE,uHACE,sBAAA","sourcesContent":["@import \"../../styles/mixins/flex.scss\";\n\n.checkboxButtonWithTextbox {\n  --checkbox-button-with-textbox-border-color-default: var(--color-iq-tuna);\n  --checkbox-button-with-textbox-border-color-active: var(--color-iq-tuna);\n  --checkbox-button-with-textbox-color-default: var(--color-dove-gray);\n  --checkbox-button-with-textbox-color-active: var(--color-iq-tuna);\n\n  .content {\n    display: flex;\n    gap: 12px;\n    flex-flow: row wrap;\n\n    .inputWrapper {\n      flex-grow: 1;\n    }\n\n    .textbox {\n      min-width: 80px;\n\n      &:not(.disabled) {\n\n        * {\n          cursor: text !important;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkboxButtonWithTextbox": "styles_checkboxButtonWithTextbox__+oZzu",
	"content": "styles_content__DNcFh",
	"inputWrapper": "styles_inputWrapper__G5ASE",
	"textbox": "styles_textbox__KtMV5",
	"disabled": "styles_disabled__NiKJ3"
};
export default ___CSS_LOADER_EXPORT___;
