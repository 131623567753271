import { FormattedTextboxTransform } from "../components/FormattedTextbox";

export function format(value: string, prefix?: string, blocks?: Array<number>, delimiters?: Array<string>, allowedCharacters?: string, transform?: FormattedTextboxTransform) {
  let indexInBlock = 0;
  let blockIndex = 0;
  let resultFormattedValue = "";
  let resultValue = "";

  if (prefix !== undefined) {
    if (value.startsWith(prefix)) {
      value = value.substring(prefix.length);
    }
    else if (prefix.startsWith(value)) {
      value = "";
    }
  }

  for (let index = 0; index < value.length; index++) {
    const character = value[index];

    if (allowedCharacters !== undefined && !allowedCharacters.includes(character)) {
      continue;
    }

    if (blocks !== undefined && indexInBlock === blocks[blockIndex]) {
      resultFormattedValue += ((delimiters && delimiters[blockIndex]) ? delimiters[blockIndex] : " ");
      blockIndex++;
      indexInBlock = 0;
    }
    if (transform === FormattedTextboxTransform.Uppercase) {
      resultFormattedValue += character.toUpperCase();
      resultValue += character.toUpperCase();
    }
    else if (transform === FormattedTextboxTransform.Lowercase) {
      resultFormattedValue += character.toLowerCase();
      resultValue += character.toLowerCase();
    }
    else {
      resultFormattedValue += character;
      resultValue += character;
    }
    indexInBlock++;
  }

  if (blocks !== undefined) {
    const length = blocks.reduce((result, length) => result + length, 0);
    resultFormattedValue = resultFormattedValue.substring(0, length + blocks.length - 1);
    resultValue = resultValue.substring(0, length);
  }

  if (prefix !== undefined && resultFormattedValue.length > 0) {
    resultFormattedValue = prefix + resultFormattedValue;
  }

  return {
    value: resultValue,
    formattedValue: resultFormattedValue,
  };
}