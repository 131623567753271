// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_gridContainer__d2wf4{display:grid;width:100%}.styles_gridContainer__d2wf4.styles_column__G5DOD{grid-auto-flow:column}.styles_gridContainer__d2wf4.styles_row__bY0v-{grid-auto-flow:row}.styles_gridContainer__d2wf4.styles_left__8vMUZ{justify-items:flex-start}.styles_gridContainer__d2wf4.styles_center__1JKxY{justify-items:center}.styles_gridContainer__d2wf4.styles_right__dcVTY{justify-items:flex-end}.styles_gridContainer__d2wf4.styles_top__cnZS8{align-items:flex-start}.styles_gridContainer__d2wf4.styles_middle__LLId4{align-items:center}.styles_gridContainer__d2wf4.styles_bottom__pIwiA{align-items:flex-end}", "",{"version":3,"sources":["webpack://./src/components/GridContainer/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,YAAA,CACA,UAAA,CAEA,kDACE,qBAAA,CAGF,+CACE,kBAAA,CAGF,gDACE,wBAAA,CAGF,kDACE,oBAAA,CAGF,iDACE,sBAAA,CAGF,+CACE,sBAAA,CAGF,kDACE,kBAAA,CAGF,kDACE,oBAAA","sourcesContent":[".gridContainer {\n  display: grid;\n  width: 100%;\n\n  &.column {\n    grid-auto-flow: column;\n  }\n\n  &.row {\n    grid-auto-flow: row;\n  }\n\n  &.left {\n    justify-items: flex-start;\n  }\n\n  &.center {\n    justify-items: center;\n  }\n\n  &.right {\n    justify-items: flex-end;\n  }\n\n  &.top {\n    align-items: flex-start;\n  }\n\n  &.middle {\n    align-items: center;\n  }\n\n  &.bottom {\n    align-items: flex-end;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gridContainer": "styles_gridContainer__d2wf4",
	"column": "styles_column__G5DOD",
	"row": "styles_row__bY0v-",
	"left": "styles_left__8vMUZ",
	"center": "styles_center__1JKxY",
	"right": "styles_right__dcVTY",
	"top": "styles_top__cnZS8",
	"middle": "styles_middle__LLId4",
	"bottom": "styles_bottom__pIwiA"
};
export default ___CSS_LOADER_EXPORT___;
