import { useState, VoidFunctionComponent } from "react";
import { ReactComponent as Eye } from "../../img/eye.svg";
import InputError from "../InputError";
import InputLabel from "../InputLabel";
import TextAlignWrapper, { TextAlign } from "../TextAlignWrapper";
import { InputProps } from "../Input";
import textboxStyles from "../Input/styles.module.scss";
import styles from "./styles.module.scss";
import Textbox from "../Textbox";
import FlexContainer from "../FlexContainer";

export type PasswordProps = Omit<InputProps, "type">;

const Password: VoidFunctionComponent<PasswordProps> = ({ className, label, error, errorStyle, placeholder, disabled, textAlign, ...props }: PasswordProps) => {
  const [opened, setOpened] = useState(false);

  return (
    <label className={`${textboxStyles.input} ${styles.password} ${className ?? ""}`}>
      {label && <InputLabel disabled={disabled}>{label}</InputLabel>}
      <FlexContainer className={styles.inputWrapper}>
        <TextAlignWrapper textAlign={textAlign ?? TextAlign.Left}>
          <Textbox
            type={opened ? "text" : "password"}
            className={`${styles.textbox} ${error !== undefined ? textboxStyles.error : ""}`}
            disabled={disabled}
            placeholder={placeholder}
            {...props}
          />
        </TextAlignWrapper>
        <Eye
          className={styles.icon}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setOpened(!opened);
          }}
        />
      </FlexContainer>
      <div className={styles.errorContainer}>
        <InputError errorStyle={errorStyle}>{error}</InputError>
      </div>
    </label>
  );
};

export default Password;
