import { forwardRef, PropsWithChildren } from "react";
import Radio, { RadioProps, RadioRef } from "../Radio";
import styles from "./styles.module.scss";

export enum RadioButtonHeight {
  High = "high",
  Low = "low",
}

export type RadioButtonProps = PropsWithChildren<RadioProps> & {
  height?: RadioButtonHeight,
};

export type RadioButtonRef = RadioRef;

const RadioButton = ({ className, children, disabled, height, ...props }: RadioButtonProps, ref: RadioButtonRef) => {
  return (
    <label className={`${styles.radioButton} ${styles[height ?? RadioButtonHeight.High]} ${disabled ? styles.disabled : ""} ${className ?? ""}`}>
      <Radio ref={ref} disabled={disabled} {...props} />
      <div className={styles.content}>{children}</div>
    </label>
  );
};

export default forwardRef(RadioButton);