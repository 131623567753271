import { ForwardedRef, forwardRef, LabelHTMLAttributes } from "react";
import { Theme } from "..";
import styles from "./styles.module.scss";

export type InputWrapperProps = LabelHTMLAttributes<HTMLLabelElement> & {
  disabled?: boolean,
  error?: string,
  theme?: Theme
};

export type InputWrapperRef = ForwardedRef<HTMLLabelElement>;

const InputWrapper = ({ className, children, disabled, error, theme, ...props }: InputWrapperProps, ref: InputWrapperRef) => {
  return (
    <label ref={ref} className={`${styles.inputWrapper} ${error ? styles.error : ""} ${disabled ? styles.disabled : ""} ${styles[theme ?? Theme.Light]} ${className ?? ""}`} {...props}>
      {children}
    </label>
  );
};

export default forwardRef(InputWrapper);
