import { ButtonHTMLAttributes, FunctionComponent } from "react";
import { Theme } from "..";
import styles from "./styles.module.scss";

export enum ServiceButtonIconPosition {
  Left = "left",
  Right = "right",
  LeftCenter = "leftCenter",
  RightCenter = "rightCenter",
}

export enum ServiceButtonAppearance {
  Bordered = "bordered",
  Filled = "filled"
}

export enum ServiceButtonShape {
  Rectangular = "rectangular",
  Rounded = "rounded",
}

export type ServiceButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  icon?: JSX.Element,
  iconPosition?: ServiceButtonIconPosition,
  appearance?: ServiceButtonAppearance,
  shape?: ServiceButtonShape,
  theme?: Theme,
};

const ServiceButton: FunctionComponent<ServiceButtonProps> = ({ children, className, icon, iconPosition, appearance, shape, theme, ...props }: ServiceButtonProps) => {
  return (
    <button className={`${styles.serviceButton} ${styles[iconPosition ?? ServiceButtonIconPosition.RightCenter]} ${styles[appearance ?? ServiceButtonAppearance.Bordered]} ${styles[shape ?? ServiceButtonShape.Rectangular]} ${styles[theme ?? Theme.Light]} ${className ?? ""}`} {...props}>
      {icon !== undefined && (
        <span className={`${styles.icon}`}>{icon}</span>
      )}
      {children}
    </button>
  );
};

export default ServiceButton;
