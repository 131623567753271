import React, { FunctionComponent } from "react";
import styles from "./styles.module.scss";

type PropsWithClassName = {
  className?: string,
};

export enum TextAlign {
  Left = "left",
  Center = "center",
  Right = "right",
}

export type TextAlignWrapperProps = {
  textAlign: TextAlign,
  children: Array<JSX.Element> | JSX.Element,
};

const TextAlignWrapper: FunctionComponent<TextAlignWrapperProps> = ({ children, textAlign }: TextAlignWrapperProps) => {
  return (
    <>
      {React.Children.map(children, function (oldChild) {
        return Object.assign({}, oldChild, {
          props: Object.assign({}, oldChild.props, {
            className: `${(oldChild.props as PropsWithClassName).className} ${styles[textAlign]}`,
          }),
        });
      })}
    </>
  );
};

export default TextAlignWrapper;