// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../img/noDeviceImage.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_devicePreview__5u0yS{display:flex;gap:48px}.styles_devicePreview__5u0yS .styles_noDeviceImage__gR4B5{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.styles_devicePreview__5u0yS .styles_cardContainer__sd31m{background:var(--color-white);border:1px solid var(--color-iq-silver);box-sizing:border-box;border-radius:8px;padding:35px 19px;gap:24px}.styles_devicePreview__5u0yS .styles_cardContainer__sd31m .styles_image__XTfQ1{height:100%}.styles_devicePreview__5u0yS .styles_cardContainer__sd31m .styles_roundedColorWrapper__WL72i{display:flex;gap:24px}.styles_devicePreview__5u0yS .styles_cardContainer__sd31m .styles_roundedColorWrapper__WL72i .styles_text__p8vyO{font-family:\"Noto Sans\",sans-serif;font-weight:400;font-size:14px;line-height:24px;color:var(--color-iq-tuna)}.styles_devicePreview__5u0yS .styles_inputContainer__oI\\+\\+\\+{gap:36px}", "",{"version":3,"sources":["webpack://./src/components/DevicePreview/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,YAAA,CACA,QAAA,CAEA,0DACE,kDAAA,CAGF,0DACE,6BAAA,CACA,uCAAA,CACA,qBAAA,CACA,iBAAA,CACA,iBAAA,CACA,QAAA,CAEA,+EACE,WAAA,CAGF,6FACE,YAAA,CACA,QAAA,CAEA,iHACE,kCAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,0BAAA,CAKN,8DACE,QAAA","sourcesContent":[".devicePreview {\n  display: flex;\n  gap: 48px;\n\n  .noDeviceImage {\n    background: url(\"../../img/noDeviceImage.svg\");\n  }\n\n  .cardContainer {\n    background: var(--color-white);\n    border: 1px solid var(--color-iq-silver);\n    box-sizing: border-box;\n    border-radius: 8px;\n    padding: 35px 19px;\n    gap: 24px;\n\n    .image {\n      height: 100%;\n    }\n\n    .roundedColorWrapper {\n      display: flex;\n      gap: 24px;\n\n      .text {\n        font-family: \"Noto Sans\", sans-serif;\n        font-weight: 400;\n        font-size: 14px;\n        line-height: 24px;\n        color: var(--color-iq-tuna);\n      }\n    }\n  }\n\n  .inputContainer {\n    gap: 36px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"devicePreview": "styles_devicePreview__5u0yS",
	"noDeviceImage": "styles_noDeviceImage__gR4B5",
	"cardContainer": "styles_cardContainer__sd31m",
	"image": "styles_image__XTfQ1",
	"roundedColorWrapper": "styles_roundedColorWrapper__WL72i",
	"text": "styles_text__p8vyO",
	"inputContainer": "styles_inputContainer__oI+++"
};
export default ___CSS_LOADER_EXPORT___;
