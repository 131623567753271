import { FunctionComponent, DetailedHTMLProps, TextareaHTMLAttributes } from "react";
import InputError, { ErrorStyle } from "../InputError";
import InputLabel from "../InputLabel";
import styles from "./styles.module.scss";

export type TextareaProps = DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> & {
  label?: string | undefined,
  error?: string | undefined,
  errorStyle?: ErrorStyle,
};

const Textarea: FunctionComponent<TextareaProps> = ({ className, label, error, errorStyle, disabled, ...props }: TextareaProps) => {
  return (
    <label className={`${styles.textareabox} ${className ?? ""}`}>
      {label && <InputLabel className={styles.textareaLabel} disabled={disabled}>{label}</InputLabel>}
      <textarea className={`${styles.textarea} ${error !== undefined ? styles.error : ""}`} disabled={disabled} {...props}></textarea>
      <div className={styles.errorContainer}>
        <InputError errorStyle={errorStyle}>{error}</InputError>
      </div>
    </label>
  );
};

export default Textarea;
