// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_heading1__OJzLz{font-family:var(--font-main);font-weight:700;font-size:36px;line-height:36px;color:var(--heading-1-color-default)}.styles_heading1__OJzLz.styles_light__5CVDF{--heading-1-color-default: var(--color-mine-shaft)}.styles_heading1__OJzLz.styles_dark__7BwGq{--heading-1-color-default: var(--color-soft-white)}", "",{"version":3,"sources":["webpack://./src/components/Heading1/styles.module.scss"],"names":[],"mappings":"AAAA,wBACE,4BAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,oCAAA,CAEA,4CACE,kDAAA,CAGF,2CACE,kDAAA","sourcesContent":[".heading1 {\n  font-family: var(--font-main);\n  font-weight: 700;\n  font-size: 36px;\n  line-height: 36px;\n  color: var(--heading-1-color-default);\n\n  &.light {\n    --heading-1-color-default: var(--color-mine-shaft);\n  }\n\n  &.dark {\n    --heading-1-color-default: var(--color-soft-white);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading1": "styles_heading1__OJzLz",
	"light": "styles_light__5CVDF",
	"dark": "styles_dark__7BwGq"
};
export default ___CSS_LOADER_EXPORT___;
